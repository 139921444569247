(function ($, root, undefined) {

    $(function () {

        'use strict';
        
        initAnimations();
        initSliderFilms();
        initSliderDecors();
        initNavLang();
        initZoho();
        
        /************
          FUNCTIONS
        *************/
        function initAnimations() {
            var scrollElements = $('.scroll-animated');
            var windowHeight = $(window).innerHeight();
            
            $(window).off('scroll', throttle(animElements, 100, false, true));
            $(window).on('scroll', throttle(animElements, 100, false, true));            

            function animElements() {
                var progress = $(window).scrollTop();
                
                scrollElements.each(function() {
                    if ($(this).offset().top - (windowHeight / 1.5) < progress) {
                        $(this).addClass('is-visible');
                    }
                });
            }
        }
        
        function initSliderFilms() {
            var slider = $('.films-carousel');
            
            if (slider.length) {
                if ($(window).width() >= 768) {
                    slider.removeClass('owl-carousel');
                    slider.trigger('destroy.owl.carousel');
                } else {                    
                    slider.addClass('owl-carousel');
                    slider.owlCarousel({
                        loop:false,
                        items:2,
                        nav:false,
                        dots: false,
                        responsive:{
                            0:{
                                items:2
                            },
                            768:{
                                items:3
                            }
                        }
                    });
                }
            }
        }
        
        function initSliderDecors() {
            var slider = $('.slider-decors');
            
            if (slider.length) {                 
                slider.addClass('owl-carousel');
                slider.owlCarousel({
                    loop:true,
                    autoplay: false,
                    items:1,
                    nav:false,
                    dots: true,
                    onInitialized: function() {
                        var wrapper = slider.parent().find('.wrapper');
                        wrapper.detach().appendTo(slider);
                    }
                });
            }
        }
        
        function initNavLang() {
//            $('header .languages>.current').click(function() {
//                $('#search-desktop').toggleClass('is-lang');
//            });
//            
            var search = $('.search-trigger');
            
            search.on('click', function() {
                $('header nav .languages').addClass('is-hidden');
            });
        }
        
        function initZoho() {
            $('[id=embedLink]').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-zoho',
                fixedContentPos: true,
                overflowY: 'hidden'
            });
        }
        
        // Resize
        function resize() {            
            initAnimations();
            initSliderFilms();
        }
        $(window).on('resize', throttle(resize, 100, false, true));
        
        // Throttle
        function throttle(func, wait, leading, trailing, context) {
            var ctx, args, result;
            var timeout = null;
            var previous = 0;
            var later = function() {
                previous = new Date;
                timeout = null;
                result = func.apply(ctx, args);
            };
            return function() {
                var now = new Date;
                if (!previous && !leading) previous = now;
                var remaining = wait - (now - previous);
                ctx = context || this;
                args = arguments;
                if (remaining <= 0) {
                    clearTimeout(timeout);
                    timeout = null;
                    previous = now;
                    result = func.apply(ctx, args);
                } else if (!timeout && trailing) {
                    timeout = setTimeout(later, remaining);
                }
                return result;
            };
        };
        

    });
})(jQuery, this);